@import './material/calendar';
@import './material/slide-toggle';
@import './material/table';
@import './material/tabs';
@import './responsive.scss';
// dialog styles

.cdk-overlay-pane {
  &.hidden {
    display: none;
  }

  @include mobile {
    width: 100%;
    max-width: 90vw !important;
  }
}

.dark-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

.hidden-dialog {
  opacity: 0;
}

mat-dialog-container {
  &.mat-dialog-container {
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    color: #000;
  }

  .dialog-header {
    position: relative;

    &.flex {
      display: flex;
    }

    .close-dialog-btn {
      position: absolute;
      top: 0;
      right: 0;
      height: 38px;
      width: 38px;
      cursor: pointer;
      border: none;
      border-radius: 50%;
      outline: none;
      background-color: #e4e4e4;
      background-image: url('/assets/icons/close.svg');
      background-position: center;
      background-repeat: no-repeat;

      &:hover {
        background-image: url('/assets/icons/close-hover.svg');
      }
    }

    .back-btn {
      background-color: #fc6f00;
      background-image: url('/assets/icons/back-white.svg');
      background-position: center;
      background-repeat: no-repeat;
      width: 38px;
      height: 38px;
      cursor: pointer;
      border: none;
      border-radius: 50%;
      outline: none;
      margin-right: 18px;
    }
  }

  .mat-dialog-title {
    opacity: 0.9;
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    color: #000;
    padding: 6px 0;
    margin: 0;
  }

  .mat-dialog-content {
    padding: 0;
    margin: 0;
    max-height: 70vh;
  }

  .mat-dialog-actions {
    margin: 0;
    padding: 0;
    min-height: auto;

    &.align-right {
      justify-content: flex-end;
    }
  }
}

.create-edit-timeslot {
  .mat-dialog-container {
    padding: 30px 0;
  }
}

// radio-button
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #fc6f00;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: #fc6f00;
}

// menu styles

.mat-menu-panel {
  &.time-menu,
  &.zones-menu {
    width: 160px;
    margin-top: 8px;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 0;

    .mat-menu-content {
      padding: 0;
      display: flex;
    }
  }
  &.zones-menu {
    width: 200px;
    overflow-x: hidden;
  }

  &.unread-messages-menu {
    margin-top: 8px;
    width: 250px;

    ng-scrollbar {
      --scrollbar-color: #eee;
      --scrollbar-thumb-color: #fc6f00;
      --scrollbar-border-radius: 2.5px;
      --scrollbar-size: 3px;
      --scrollbar-thumb-hover-color: #fc6f00;
      // --scrollbar-padding: 0 5px;
    }
  }

  &.track-menu,
  &.playlist-menu,
  &.bucket-menu {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    height: fit-content;

    .mat-menu-content {
      padding: 20px;
    }

    .menu-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .menu-item-icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        background-repeat: no-repeat;

        &.icon-heart-empty {
          background-image: url(/assets/icons/heart-empty.svg);
        }

        &.icon-heart-filled {
          background-image: url(/assets/icons/heart-filled.svg);
        }

        &.icon-add-to-list {
          background-image: url(/assets/icons/add-to-list.svg);
        }

        &.icon-opened-book {
          background-image: url(/assets/icons/opened-book.svg);
        }

        &.icon-opened-book-strike {
          background-image: url(/assets/icons/opened-book-strike.svg);
        }

        &.icon-artist {
          background-image: url(/assets/icons/artist.svg);
        }

        &.icon-bucket {
          background-image: url(/assets/icons/bucket.svg);
        }

        &.icon-queue {
          background-image: url(/assets/icons/note-list.svg);
        }

        &.icon-delete {
          background-image: url(/assets/icons/delete.svg);
        }

        &.icon-heart-broken {
          background-image: url(/assets/icons/heart-broken.svg);
        }

        &.icon-heart-broken-filled {
          background-image: url(/assets/icons/heart-broken-filled.svg);
        }

        &.icon-edit-empty {
          background-image: url(/assets/icons/edit-empty.svg);
        }
      }

      .menu-item-label {
        margin-bottom: 1px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.17px;
      }

      &:hover {
        color: #fc6f00;
        text-decoration: none;

        .icon-heart-empty {
          background-image: url(/assets/icons/heart-empty-hover.svg);
        }

        .icon-bucket {
          background-image: url(/assets/icons/bucket-hover.svg);
        }

        .icon-add-to-list {
          background-image: url(/assets/icons/add-to-list-hover.svg);
        }

        .icon-opened-book {
          background-image: url(/assets/icons/opened-book-hover.svg);
        }

        .icon-opened-book-strike {
          background-image: url(/assets/icons/opened-book-strike-hover.svg);
        }

        .icon-artist {
          background-image: url(/assets/icons/artist-hover.svg);
        }

        .icon-queue {
          background-image: url(/assets/icons/note-list-hover.svg);
        }

        .icon-delete {
          background-image: url(/assets/icons/delete-hover.svg);
        }

        .icon-heart-broken {
          background-image: url(/assets/icons/heart-broken-hover.svg);
        }

        .icon-edit-empty {
          background-image: url(/assets/icons/edit-empty-hover.svg);
        }

        .icon-remove-from-bucket {
          background-image: url(/assets/icons/delete-from-bucket-hover.svg);
        }
      }
    }
  }
}

// slider styles

.mat-slider {
  &.crossfade-duration-slider {
    display: block;
    width: calc(100% - 6px);
    height: 100%;
    padding: 0;

    &.mat-slider-sliding {
      .mat-slider-thumb {
        width: 16px;
        height: 16px;
        top: -8px;
        right: -8px;
      }
    }

    &:hover {
      .mat-slider-track-background,
      .mat-slider-track-fill {
        background-color: #e4e4e4;
      }
    }

    &.mat-slider-min-value {
      .mat-slider-thumb-container {
        .mat-slider-thumb {
          border-width: 0;
          background-color: #fc6f00;
        }
      }
    }

    &.cdk-mouse-focused {
      .mat-slider-thumb-container {
        .mat-slider-thumb {
          border-width: 0;
        }
      }
    }

    .mat-slider-wrapper {
      top: 4px;
      left: 0;
      right: 0;
    }

    .mat-slider-track-wrapper {
      background-color: #e4e4e4;
    }

    .mat-slider-track-background,
    .mat-slider-track-fill {
      background-color: #e4e4e4;
    }

    .mat-slider-thumb {
      border-width: 0;
      width: 10px;
      height: 10px;
      top: -5px;
      right: -5px;
      bottom: 0;
      transform: none;

      &:hover {
        width: 16px;
        height: 16px;
        top: -8px;
        right: -8px;
      }
    }
  }

  &.venue-settings-slider {
    display: block;
    width: 100%;
    height: 13px;
    padding: 0;

    &.mat-slider-min-value {
      .mat-slider-thumb-container {
        .mat-slider-thumb {
          border-width: 0;
          background-color: #fc6f00;
        }
      }
    }

    &:hover {
      .mat-slider-track-background {
        background-color: unset;
      }
    }

    .mat-slider-thumb {
      width: 13px;
      height: 13px;
      top: -6.5px;
      right: -6.5px;
      transform: none;
    }

    .mat-slider-wrapper {
      top: 5px;
      left: 0;
      right: 0;
    }

    .mat-slider-track-wrapper {
      background-color: #e4e4e4;
      height: 3px;
    }

    .mat-slider-track-background {
      background-color: #e4e4e4;
    }
  }

  &.player-slider {
    .mat-slider-track-background {
      background-color: #e4e4e4;
    }
  }
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: #fc6f00;
}

//spinner styles

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #fc6f00;
}

// sidenav styles

.mat-drawer-container {
  background-color: #fff;
  color: #000;
}

.mat-drawer-backdrop {
	&.mat-drawer-shown {
		background-color: rgba(0, 0, 0, .4);
	}
}

.mat-badge-content {
  background: #fc6f00;
}

.zone-panel-class {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
  .mat-option {
    background-color: #f7f7f7;
    border: 0.5px solid #fff;
    &.mat-selected {
      background-color: #f7f7f7;
    }
    &.current-selected {
      background: #e0e0e0 !important;
    }
  }
  .mat-option:focus:not(.mat-option-disabled), .mat-option:hover:not(.mat-option-disabled) {
    background-color: #d8d8d8;
    color: #fc6f00;

    .menu-icon {
      &.icon-audio{
        background-image: url(/assets/icons/audio-hover.svg);
      }

      &.icon-video {
        background-image: url(/assets/icons/video-hover.svg);
      }

      &.icon-channel {
        background-image: url(/assets/icons/channel-hover.svg);
      }
    }
    .zone-item {
      color: #fc6f00;
    }
  }
}

// material table
table.mat-table {
  width: 100%;
}

.mat-elevation-z8 {
  box-shadow: none;
}

table {
  border: none;
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-width: 1px !important;
}

.mat-header-cell {
  font-size: 18px;
  color: #000;
}

td.mat-cell:first-of-type, th.mat-header-cell:first-of-type {
  padding-left: 10px;
}
td.mat-cell:last-of-type, th.mat-header-cell:last-of-type {
  padding-right: 10px;
}

td.mat-column-options {
  width: 36px;
}

.mat-cell {
  font-size: 16px;
  font-weight: 300;
  max-width: 200px;
  padding-right: 10px !important;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  a {
    color: #000000;
  }
}

.big-tooltip {
  font-size: 16px !important;
  font-weight: 300 !important;
}
