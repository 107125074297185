@import "../base";

.icon-chevron-down {
  @include svg-icon('~styles/icons/chevron-left/chevron-left.svg');
  transform: rotate(-90deg);
}

.icon-chevron-up {
  @include svg-icon('~styles/icons/chevron-left/chevron-left.svg');
  transform: rotate(90deg);
}
