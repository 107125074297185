$slideToggleColour: #60799F;
$slideToggleActiveColour: #19A974;

.mat-slide-toggle {
  &:not(.mat-checked):not(.mat-disabled) {
    .mat-slide-toggle-bar,
    .mat-slide-toggle-thumb {
      background-color: $slideToggleColour;
    }
  }

  &.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-bar,
    .mat-slide-toggle-thumb {
      background-color: $slideToggleActiveColour;
    }
  }
}
