/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'angular-calendar/css/angular-calendar.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import './styles/boomr-bootstrap.scss';
@import './styles/boomr-material.scss';
@import './styles/boomr-owl.scss';
@import './styles/icons.scss';
@import './styles/icons/index';
@import './assets/styles/fonts.scss';


* {
  font-family: 'Helvetica Neue', sans-serif;
}

body {
  color: #000;

  .pac-container {
    position: fixed !important;
  }
}

.mat-snack-bar-container {
  background-color: #123456;
  color: #fff; 
}