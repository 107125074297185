$tinyPhoneWidth: 360px !default;
$smallPhoneWidth: 576px !default;
$mobileWidth: 768px !default;
$tabletWidth: 992px !default;
$desktopWidth: 1280px !default;

/* Mixins to be used by the app */
@mixin tiny-phone {
  @media (max-width: $tinyPhoneWidth) {
    @content;
  }
}

@mixin small-phone {
  @media (max-width: $smallPhoneWidth) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobileWidth) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tabletWidth) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $desktopWidth) {
    @content;
  }
}
