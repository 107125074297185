@import './responsive.scss';

owl-carousel {
  &.promoted-playlist-carousel,
  &.video-playlist-carousel,
  &.channel-carousel,
  &.popular-artists-carousel,
  &.today-timeslots-carousel,
  &.artists-carousel {
    .owl-stage {
      padding-left: 0 !important;
    }
  }

  &.popular-artists-carousel,
  &.today-timeslots-carousel,
  &.artists-carousel {
    .owl-item:not(.active) {
      opacity: 0.25;

      image {
        opacity: 0.5;
      }
    }
  }

  &.schedules-carousel {
    .owl-carousel .owl-stage-outer {
      padding: 10px;
      margin: -10px;
    }
  }
}

.nav-container {
  display: inline-flex;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 36px;
    opacity: 0.7;
    background-color: transparent;
    z-index: 2;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  .owl-prev,
  .owl-next,
  .owl-next-menu,
  .owl-prev-menu,
  .owl-prev-player,
  .owl-next-player {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #fff;
    z-index: 3;

    span {
      display: block;
      background-image: url('/assets/icons/arrow.svg');
      background-repeat: no-repeat;
      background-size: 22px;
      background-position: center;
      font-size: 0;
      height: 22px;
      width: 22px;
    }

    &.disabled {
      opacity: 0.3;
      cursor: unset;
      z-index: 1;
    }

    &:hover {
      background-color: #fff;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
    }
  }

  .owl-prev,
  .owl-prev-menu,
  .owl-prev-player {
    margin-right: 6px;

    span {
      margin-right: 1px;
    }
  }

  .owl-next,
  .owl-next-menu,
  .owl-next-player {
    margin-left: 6px;

    span {
      transform: rotate(180deg);
      margin-left: 1px;
    }
  }

  &.players-carousel-nav {
    position: absolute;
    justify-content: space-between;
    right: 14px;
    left: 14px;
    top: 20px;

    &:before,
    &:after {
      width: 24px;
    }

    .btn {
      width: 24px;
      height: 24px;

      span {
        height: 14px;
        width: 18px;
      }
    }
  }

  &.zone-players-carousel-nav {
    position: absolute;
    justify-content: space-between;
    right: 20px;
    width: 200px;

    &:before,
    &:after {
      width: 30px;
    }

    .btn {
      width: 30px;
      height: 30px;
      background-color: #f3f3f3;

      span {
        background-size: 20px;
      }
    }
  }

  &.miniplayers-carousel {
    .owl-stage-outer {
      overflow: visible;
    }
    .miniplayers-carousel-nav {
      position: absolute;
      justify-content: space-between;
    }
  }
}
