input,
.form-control,
textarea {
  height: 46px;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-weight: 500;
  letter-spacing: 0.18px;
  color: #000;
  font-size: 15px;
  border: none;
  line-height: 18px;
  padding-left: 16px;

  &:focus {
    color: #000;
    box-shadow: 0 0 0 1px #fc6f00;
    outline: 0;
    background-color: #f5f5f5;
  }

  &::placeholder {
    color: #000;
    opacity: 0.4;
  }

  &.input-search {
    background-color: #efefef;
    background-image: url('/assets/icons/search-dark.svg');
    background-repeat: no-repeat;
    background-position: 11px 13px;
    background-size: 24px;
    padding-left: 47px;
  }
}

.btn {
  border-radius: 3px;
  background-color: #f5f5f5;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.19px;
  text-align: center;
  color: #000;
  padding: 15px 12px 16px;

  &:hover {
    color: #fc6f00;
  }

  &:active {
    outline: none;
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    color: #000;
  }

  &[disabled] {
    opacity: 0.3;
  }
}
