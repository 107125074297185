@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  src: local('Helvetica Neue Light'), url('/assets/fonts/helvetica-neue-5/HelveticaNeueLight.otf') format('otf');
}

@font-face {
  font-family: 'Helvetica Neue Italic';
  font-style: italic;
  font-weight: 300;
  src: local('Helvetica Neue Italic'), url('/assets/fonts/helvetica-neue-5/HelveticaNeueLightItalic.otf') format('otf');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica Neue'), url('/assets/fonts/helvetica-neue-5/HelveticaNeueMedium.otf') format('otf');
}

@font-face {
  font-family: 'Helvetica Neue Italic';
  font-style: italic;
  font-weight: 400;
  src: local('Helvetica Neue Italic'), url('/assets/fonts/helvetica-neue-5/HelveticaNeueMediumItalic.otf') format('otf');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  src: local('Helvetica Neue'), url('/assets/fonts/helvetica-neue-5/HelveticaNeueMedium.otf') format('otf');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: italic;
  font-weight: 500;
  src: local('Helvetica Neue Oblique'), url('/assets/fonts/helvetica-neue-5/HelveticaNeueMediumItalic.otf') format('otf');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/helvetica-neue-5/HelveticaNeueBold.otf') format('otf');
}
  
// @font-face {
//   font-family: 'Helvetica Neue';
//   font-style: normal;
//   font-weight: 800;
//   src: local('Helvetica Neue Bold Oblique'), url('/assets/fonts/helvetica-neue-5/HelveticaNeueBoldItalic.otf') format('otf');
// }

// @font-face {
//   font-family: 'HelveticaNeueBT';
//   font-style: normal;
//   font-weight: 800;
//   src: local('Helvetica Neue'), url('/assets/fonts/helvetica-neue-5/HelveticaNeueBold.otf') format('otf');
// }
  
// @font-face {
//   font-family: 'HelveticaNeueBT';
//   font-style: normal;
//   font-weight: 800;
//   src: local('Helvetica Neue Bold Oblique'), url('/assets/fonts/helvetica-neue-5/HelveticaNeueBoldItalic.otf') format('otf');
// }