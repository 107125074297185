@mixin icon {
  background-color: black;

  &:hover {
    background-color: #fc6f00;
  }

  .dark &, &.dark {
    background-color: white;
  }
}

@mixin svg-icon($url) {
  @include icon;
  mask-image: url($url);
}
