mat-calendar {
  .mat-calendar-table-header-divider::after {
    display: none;
  }

  .mat-calendar-body-label {
    visibility: hidden;
    padding: 0!important;
  }

  .mat-focus-indicator {
    border-radius: 100% !important;
  }

  mat-calendar-header {
    .mat-calendar-previous-button, .mat-calendar-next-button {
      outline: none;
    }
    .mat-calendar-period-button {
      outline: none;
    }
  }

  mat-month-view {
    .mat-calendar-table-header {
      th {
        font-weight: 800;
        font-family: Futura!important;
        font-size: 14px;
        padding: 0px;
      }
    }

    .mat-calendar-body {
      tr {
        :first-child, .mat-calendar-body-label + .mat-calendar-body-cell {
          border-bottom-left-radius: 50%;
          border-top-left-radius: 50%;
        }

        :last-child {
          border-bottom-right-radius: 50%;
          border-top-right-radius: 50%;
        }

        .mat-calendar-body-cell {
          &.selected-cell {
            .mat-calendar-body-cell-content {
              border-bottom-right-radius: 50%;
              border-top-right-radius: 50%;
              background-color: #fc6f00;
              color: white;
            }
          }
          &.same-week-as-selected {
            background-color: rgba(#fc6f00, 0.15);
          }

          .mat-calendar-body-cell-content {
            top: 4px;
            left: 4px;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
          }
        }
      }
    }
  }

  .mat-calendar-body-selected {
    background-color: #fc6f00;

    &.mat-calendar-body-today {
      box-shadow: none;
    }
  }
}

