mat-tab-header {
  border: 1px solid #e9eaf0;
  border-radius: 8px;
  padding: 4px;

  .mat-tab-labels {
    justify-content: space-between;
  }

  .mat-tab-label {
    border-radius: 6px;
    width: 100%;
    min-width: unset;
    height: 34px;
    opacity: 1;

    &.mat-tab-label-active {
      background: #fc6f00;
      color: white;
      font-weight: 800;
    }
  }
}

mat-tab-group.jukeo-tabs {
  mat-tab-header {
    width: 280px;

    .mat-tab-label {
      height: 52px;
    }
  }
}
