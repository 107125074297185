.icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  cursor: pointer;
}
.icon-help {
  background-image: url('/assets/icons/help.svg');
}

.icon-edit {
  background-image: url('/assets/icons/edit.svg');

  &:hover {
    background-image: url('/assets/icons/edit-hover.svg');
  }
}

.icon-edit-empty {
  background-image: url('/assets/icons/edit-empty.svg');

  &:hover {
    background-image: url('/assets/icons/edit-empty-hover.svg');
  }
}

.icon-edit-empty-white {
  background-image: url('/assets/icons/edit-empty-white.svg');

  // &:hover {
  //   background-image: url('/assets/icons/edit-empty-hover.svg');
  // }
}

.icon-bubble {
  background-image: url('/assets/icons/bubble.svg');

  // &:hover {
  //   background-image: url('/assets/icons/bubble-hover.svg');
  // }
}

.icon-delete {
  background-image: url('/assets/icons/delete.svg');

  &:hover {
    background-image: url('/assets/icons/delete-hover.svg');
  }
}

.icon-delete-primary {
  background-image: url('/assets/icons/delete-hover.svg');
}

.icon-more {
  background-image: url('/assets/icons/more.svg');
}

.icon-more-dark {
  background-image: url('/assets/icons/more-dark.svg');
}

.icon-more,
.icon-more-dark {
  &:hover {
    background-image: url('/assets/icons/more-hover.svg');
  }
}

.icon-send {
  background-image: url('/assets/icons/send.svg');

  &:hover {
    background-image: url('/assets/icons/send-hover.svg');
  }
}

.icon-checkmark {
  background-image: url('/assets/icons/checkmark-dark.svg');
}

.icon-spotify {
  background-image: url('/assets/icons/spotify.svg');

  &:hover {
    background-image: url('/assets/icons/spotify-hover.svg');
  }
}

.icon-notification {
  background-image: url('/assets/icons/notification.svg');

  &:hover {
    background-image: url('/assets/icons/notification-hover.svg');
  }
}

.icon-repeat {
  background-image: url('/assets/icons/repeat.svg');

  &:hover {
    background-image: url('/assets/icons/repeat-hover.svg');
  }
}

.icon-menu {
  background-image: url('/assets/icons/menu.svg');

  &:hover {
    background-image: url('/assets/icons/menu-hover.svg');
  }
}

.icon-plus {
  background-image: url('/assets/icons/plus.svg');

  &:hover {
    background-image: url('/assets/icons/plus-hover.svg');
  }
}

.icon-plus-primary {
  background-image: url('/assets/icons/plus-hover.svg');
}

.icon-heart-empty {
  background-image: url('/assets/icons/heart-empty.svg');

  &:not(.icon-without-hover):hover {
    background-image: url('/assets/icons/heart-empty-hover.svg');
  }
}

.icon-heart-empty-light {
  background-image: url('/assets/icons/heart-empty-light.svg');

  &:hover {
    background-image: url('/assets/icons/heart-empty-hover.svg');
  }
}

.icon-heart-filled {
  background-image: url('/assets/icons/heart-filled.svg');
}

.icon-artist {
  background-image: url('/assets/icons/artist.svg');

  &:hover {
    background-image: url('/assets/icons/artist-hover.svg');
  }
}

.icon-queue {
  background-image: url('/assets/icons/note-list.svg');

  &:hover {
    background-image: url('/assets/icons/note-list-hover.svg');
  }
}

.icon-heart-broken {
  background-image: url('/assets/icons/heart-broken.svg');

  &:hover {
    background-image: url('/assets/icons/heart-broken-hover.svg');
  }
}

.icon-heart-broken-filled {
  background-image: url('/assets/icons/heart-broken-filled.svg');
}

.icon-settings {
  background-image: url('/assets/icons/settings.svg');

  &:hover {
    background-image: url('/assets/icons/settings-hover.svg');
  }
}

.icon-prev-track {
  background-image: url('/assets/icons/next-track.svg');
  transform: rotate(180deg);
}

.icon-play {
  background-image: url('/assets/icons/play-arrow.svg');
}

.icon-play-arrow-orange {
  background-image: url('/assets/icons/play-arrow-hover.svg');
}

.icon-play-black {
  background-image: url('/assets/icons/play-arrow-black.svg');
}

.icon-play-orange {
  background-image: url('/assets/icons/play-circle.svg');
}

.icon-pause {
  background-image: url('/assets/icons/pause-button.svg');
}

.icon-next-track {
  background-image: url('/assets/icons/next-track.svg');
}

.icon-logout {
  background-image: url('/assets/icons/logout.svg');

  &:hover {
    background-image: url('/assets/icons/logout-hover.svg');
  }
}

.icon-remove-from-bucket {
  background-image: url('/assets/icons/delete-from-bucket.svg');
}

.icon-bucket-white {
  background-image: url('/assets/icons/bucket-white.svg');
}

.icon-book-white {
  background-image: url('/assets/icons/opened-book-white.svg');
}

.icon-queue-white {
  background-image: url('~assets/icons/note-list-white.svg');
}

.icon-note-list-hover {
  background-image: url('~assets/icons/note-list-hover.svg');
}

.icon-clock {
  background-image: url('~assets/icons/clock.svg');
}

.icon-copy {
  background-image: url('/assets/icons/copy.svg');

  &:hover {
    background-image: url('/assets/icons/copy-hover.svg');
  }
}

.icon-add-schedule {
  background-image: url('/assets/icons/add-schedule.svg');

  &:hover {
    background-image: url('/assets/icons/add-schedule-hover.svg');
  }
}

.icon-feedback {
  background-image: url('/assets/icons/feedback.svg');
  opacity: 0.6;
}

.icon-home {
  background-image: url('/assets/icons/home.svg');

  &:hover {
    background-image: url('/assets/icons/home-hover.svg');
  }
}

.icon-audio {
  background-image: url('/assets/icons/audio.svg');
}

.icon-video {
  background-image: url('/assets/icons/video.svg');
}

.icon-channel {
  background-image: url('/assets/icons/channel.svg');
}

// TODO add for hover image

.icon-list {
  background-image: url('/assets/icons/list.svg');
}

.icon-block {
  background-image: url('/assets/icons/sorting-tiles.svg');
}

.icon-error {
  background-image: url('/assets/icons/close-white.svg');
}
